<template>
    <div class="animated fadeIn">
        <b-row>
          <b-col sm="4">
            <b-card>
             <div class="mb-3">
              <b-form @submit.stop.prevent="submitSearch">
                <b-input-group>
                  <b-input-group-prepend is-text
                    ><i class="fa fa-search"></i
                  ></b-input-group-prepend>
                  <b-form-input
                    v-model="search"
                    placeholder="Хайх"
                  ></b-form-input>
                  <b-input-group-append
                    ><b-button variant="primary" type="submit"
                      >Хайх</b-button
                    ></b-input-group-append
                  >
                  <b-input-group-append
                    ><b-button variant="secondary"
                      ><i class="fa fa-refresh"></i
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form>
             </div>
              


              <div class="table-responsive mb-0"
              v-if="items != undefined && items.length > 0">


                <b-table
                  stacked="md"
                  hover
                  bordered
                  small
                  responsive="sm"
                  :items="items"
                  :fields="columns"
                  no-local-sorting
                >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <!-- <template v-slot:cell(amountDebit)="data">
                    <span v-if="data.item.amount > 0">{{ data.item.amount | number }}</span>
                  </template> -->

                  <template v-slot:cell(email2)="data">
                    <b-button variant="primary" size="sm" @click="showModal(data.item.email)"><i class="fa fa-eye"></i></b-button>
                  </template>
                </b-table>

              <div slot="footer" class="mb-0 mt-3">
              <nav class="pull-right" v-if="pagination.total > query.per_page"><b-pagination class="mb-0" :total-rows="pagination.total" :per-page="query.per_page" v-model="query.page"/></nav>
              <p class="mb-0">{{ pagination.total }} өгөгдлөөс {{ (pagination.to)?pagination.from:0 }} - {{ pagination.to }} өгөгдөл харагдаж байна.</p>
            </div>  
          </div>
            </b-card>
           
          </b-col>
          <b-col sm="8">
            <ContractUsers :email="email" :key="email"></ContractUsers>
          </b-col>
        </b-row>


    </div>
</template>

<script>
import moment from "moment";
import ContractUsers from "./ContractUsers"
export default {
    name: "ReportContract",
    components: {
      ContractUsers,
    },

    data: function() {
        return {
            query: {
              id: 0,
              page: 1,
              per_page: 10,
              startAt: '',
              endAt: '',
              search: '',

            },
            minDate: moment()
              .subtract(10000, "days")
              .format("YYYY-MM-DD"),
            maxDate: moment()
              .subtract(1, "days")
              .format("YYYY-MM-DD"),
            result_emails: [],
            result_data: [],
            items: [],
            _buttons: [],
            pagination: {
              total: 0,
              from: 0,
              to: 0
            },
            search: "",
            email: null,
            columns: [
                { label: "№", key: "index", class: "text-center" },
                { label: "Имэйл", key: "email", class: "text-center"},
                { label: "Хэрэглэгчид", key: "email2", class: "text-center"},
            ]
        }
    },
    created: function() {
      this.loadData();
    },
    watch: {
      query: {
        handler (query) {
          this.loadData();
        },
        deep: true
      },
    },

    methods: {
        loadData: async function() {
            console.log('hell2o')
            this.checkSession();
            this.isLoading = true;

            this.$http.get(this.$config.API_URL + "ReportWebService/get_contracted_user_email_list", {
                params: this.$data.query,
                headers: this.$store.getters.httpHeader,
                emulateJSON: true,
            }).then(
                (response) => {
                    console.log(response)
                    this.$data.items = response.body.data
                    this.$data.pagination.total = response.body.total
                    this.$data.pagination.from = response.body.from
                    this.$data.pagination.to = response.body.to

              }
            )
        },
        showModal(email) {
          console.log(email)
          this.$data.email = email;
          // this.$refs["contract-users-modal"].show();
        },
        submitSearch: function() {
          console.log('hello')
          this.$data.query.search = this.$data.search;
          this.$data.query.page = 1
        },
        // hideNote() {
        //   this.$refs["contract-users-modal"].reset();
        //   this.$refs["contract-users-modal"].hide();
        //   console.log('heas')
        // },
    }
};
</script>